import {
  BrowserRouter, Switch, Route, RouteComponentProps,
} from 'react-router-dom'

const NotFound = ({ history }: RouteComponentProps) => (
  <>
    Sorry, looks like we&apos;ve sent you the wrong way.
    <button type="button" onClick={history.goBack}>Go back</button>
    .
  </>
)

const App = () => (
  <div className="App">
    <header className="App-header">
      <h1>Nonalu</h1>
    </header>
    <BrowserRouter>
      <Switch>
        <Route exact path="/">Home</Route>
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  </div>
)

export default App
